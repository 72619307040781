<template>
    <v-card>
        <v-toolbar flat>
            <v-toolbar-title>Редактирование</v-toolbar-title>
            <v-spacer />
            <v-btn icon :to="{ name: 'NewsList' }" exact>
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <v-tabs>
                <v-tab>Главное</v-tab>
                <v-tab :disabled="id == 0">Контент</v-tab>
                <v-tab-item><news-one-main :id="id" /></v-tab-item>
                <v-tab-item><news-one-content :id="id" /></v-tab-item>
            </v-tabs>
        </v-card-text>
    </v-card>
</template> 

<script>
import NewsOneMain from "@c/News/One/Main";
import NewsOneContent from "@c/News/One/Content";
export default {
    name: "NewsOne",
    components: {
        NewsOneMain,
        NewsOneContent,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
};
</script>