<template>
    <v-card flat>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-menu>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                block
                                :disabled="loading"
                                color="primary"
                            >
                                Добавить
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="t in types"
                                :key="t.id"
                                @click="addContent(t.id)"
                            >
                                <v-list-item-icon>
                                    <v-icon v-text="t.icon" />
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ t.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row v-for="(c, i) in list" :key="c.id">
                <v-col>
                    <news-one-item
                        :first="i == 0"
                        :last="i == list.length - 1"
                        :types="types"
                        :value="c"
                        :id="id"
                        @removed="removed"
                        @sort="sort"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <news-one-content-edit
            ref="editor"
            :types="types"
            :id="id"
            @saved="saved"
        />
    </v-card>
</template> 

<script>
import NewsOneItem from "@c/News/One/Item";
import NewsOneContentEdit from "@c/News/One/ContentEdit";
export default {
    name: "NewsOneContent",
    components: {
        NewsOneItem,
        NewsOneContentEdit,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        list: [],
        types: [],
    }),
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.loading = true;
            const { types, list } = await this.$admin(
                `/news/${this.id}/content`
            );
            for (let l of list) {
                switch (l.type) {
                    case 4:
                    case 5:
                        l.file = {
                            ru: null,
                            en: null,
                        };
                        break;
                    case 6:
                    case 8:
                        Object.values(l.data).forEach((loc) => {
                            loc.forEach((d) => {
                                d.preview = `${this.$store.state.uploads}/news/${this.id}/${d.src}`;
                            });
                        });
                        break;
                }
            }
            this.list = list;
            this.types = types;
            this.loading = false;
        },
        addContent(id) {
            this.$refs.editor.open(id);
        },
        saved(value) {
            this.saveSort([{ id: value.id, sort: this.list.length }]);
            this.list.push(value);
        },
        removed(id) {
            this.list = this.list.filter((l) => l.id != id);
        },
        saveSort(body) {
            this.$admin(`/news/${this.id}/sort`, {
                method: "POST",
                body,
            });
        },
        sort(dir, id) {
            const list = [...this.list];
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    let target = list[i];
                    let another = list[dir ? i + 1 : i - 1];
                    this.saveSort([
                        { id, sort: dir ? i + 1 : i - 1 },
                        { id: another.id, sort: i },
                    ]);
                    [list[i], list[dir ? i + 1 : i - 1]] = [another, target];
                    break;
                }
            }
            this.list = list;
        },
    },
};
</script>