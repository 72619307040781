<template>
    <v-dialog v-model="show" persistent :retain-focus="false">
        <news-one-item
            :types="types"
            :value="value"
            dialog
            @removed="show = false"
            @saved="saved"
            :id="id"
        />
    </v-dialog>
</template> 

<script>
import NewsOneItem from "@c/News/One/Item";
export default {
    name: "NewsOneContentEdit",
    components: {
        NewsOneItem,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        show: false,
        value: {},
        contentId: 0,
    }),
    methods: {
        open(id) {
            let value = {
                id: --this.contentId,
                type: id,
            };
            switch (id) {
                case 1:
                    value = {
                        ...value,
                        ...{
                            text: {
                                ru: "",
                                en: "",
                            },
                            options: {
                                level: 1,
                            },
                        },
                    };
                    break;
                case 2:
                    value = {
                        ...value,
                        ...{
                            text: {
                                ru: "",
                                en: "",
                            },
                        },
                    };
                    break;
                case 3:
                    value = {
                        ...value,
                        ...{
                            text: {
                                ru: "",
                                en: "",
                            },
                            options: {
                                promo: false,
                            },
                        },
                    };
                    break;
                case 4:
                case 5:
                    value = {
                        ...value,
                        ...{
                            file: {
                                ru: null,
                                en: null,
                            },
                        },
                    };
                    break;
                case 6:
                    value = {
                        ...value,
                        ...{
                            data: {
                                ru: [],
                                en: [],
                            },
                        },
                    };
                    break;
                case 7:
                    break;
                case 8:
                    value = {
                        ...value,
                        ...{
                            data: {
                                ru: [],
                                en: [],
                            },
                        },
                    };
                    break;
                case 9:
                case 10:
                    value = {
                        ...value,
                        ...{
                            text: {
                                ru: "",
                                en: "",
                            },
                        },
                    };
                    break;
            }
            this.value = value;
            this.show = true;
        },
        saved(value) {
            this.$emit("saved", value);
            this.show = false;
        },
    },
};
</script>
