<template>
    <v-form :disabled="loading" @submit.prevent="save" ref="form">
        <v-card flat>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-select
                            label="Автор"
                            :rules="req"
                            v-model="main.author"
                            :items="authors"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Алиас"
                            v-model="main.slug"
                            clearable
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Имя"
                            :rules="req"
                            v-model="main.name.ru"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            label="Имя [En]"
                            :rules="req"
                            v-model="main.name.en"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            label="Категория"
                            :rules="req"
                            v-model="main.cat"
                            :items="cats"
                            @change="main.tags = []"
                            :disabled="id > 0"
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            label="Теги"
                            v-model="main.tags"
                            :items="selectTags"
                            :rules="reqTags"
                            multiple
                            :disabled="!main.cat"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            :rules="req"
                            v-model="main.publish"
                            label="Дата публикации"
                            type="datetime-local"
                        />
                    </v-col>
                    <v-col>
                        <v-select
                            label="Статус"
                            :items="status"
                            v-model="main.status"
                            :disabled="id == 0"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-file-input
                            label="Превью (360х180)"
                            v-model="preview"
                            accept="image/png, image/jpeg"
                            :rules="id > 0 ? [] : req"
                        />
                    </v-col>
                    <v-col>
                        <img
                            class="mx-auto d-block"
                            v-if="id > 0"
                            width="360"
                            height="180"
                            :src="`${$store.state.uploads}/news/${id}/preview.jpg?t=${mod}`"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" type="submit" text :disabled="loading">
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
        <file-uploader ref="uploader" />
    </v-form>
</template> 

<script>
import FileUploader from "@c/FileUploader";
export default {
    name: "NewsOneMain",
    components: {
        FileUploader,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        preview: null,
        mod: Date.now(),
        main: {
            name: {
                ru: null,
                en: null,
            },
            slug: null,
            author: null,
            cat: null,
            tags: [],
            status: 0,
            publish: null,
        },
        cats: [],
        authors: [],
        tags: {},
        status: [],
    }),
    computed: {
        req: () => [(v) => !!v || "Заполните поле"],
        reqTags: () => [(v) => v.length != 0 || "Заполните поле"],
        selectTags() {
            return this.tags[this.main.cat] ?? [];
        },
    },
    mounted() {
        this.getData();
    },
    methods: {
        async save() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            const body = { ...this.main };
            if (this.preview) {
                const [preview] = await this.$refs.uploader.upload([
                    this.preview,
                ]);
                body.preview = preview.tmp;
            }
            const { id, slug } = await this.$admin(`/news/${this.id}`, {
                method: "POST",
                body,
            });
            this.main.slug = slug;
            this.loading = false;
            this.mod = Date.now();
            this.preview = null;
            if (this.id == 0) {
                this.$router.push({ name: this.$route.name, params: { id } });
            }
        },
        async getData() {
            this.loading = true;
            const { data, cats, tags, status, authors } = await this.$admin(
                `/news/${this.id}`
            );
            this.cats = cats;
            this.tags = tags;
            this.authors = authors;
            this.status = status;
            if (this.id > 0) {
                this.main = data;
            }
            this.loading = false;
        },
    },
};
</script>