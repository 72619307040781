<template>
    <v-form @submit.prevent="saveContent" ref="form" :disabled="loading">
        <v-card :loading="loading">
            <v-toolbar dense flat>
                <v-toolbar-title>
                    {{ getTypeName(value.type) }}
                </v-toolbar-title>
                <template v-if="!dialog">
                    <v-spacer />
                    <v-btn
                        icon
                        :disabled="first || loading"
                        @click="$emit('sort', false, value.id)"
                    >
                        <v-icon>mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        :disabled="last || loading"
                        @click="$emit('sort', true, value.id)"
                    >
                        <v-icon>mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="error"
                        :disabled="loading"
                        @click="remove(value.id)"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="primary"
                        :disabled="disableSave"
                        type="submit"
                    >
                        <v-icon> mdi-content-save </v-icon>
                    </v-btn>
                </template>
            </v-toolbar>
            <v-card-text>
                <template v-if="value.type == 1">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-text-field
                                :label="`Текст [${l}]`"
                                :rules="req"
                                v-model="value.text[l]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                label="Уровень"
                                :rules="reqLvl"
                                v-model="value.options.level"
                                type="number"
                            />
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 2">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <editor v-model="value.text[l]" :init="mce" />
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 3">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-text-field
                                :label="`Текст [${l}]`"
                                :rules="req"
                                v-model="value.text[l]"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox
                                label="Промо"
                                v-model="value.options.promo"
                            />
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 4">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-file-input
                                :label="`Загрузка [${l}]`"
                                :rules="value.id < 0 ? req : []"
                                accept="image/jpeg, image/png"
                                v-model="value.file[l]"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="value.id > 0">
                        <v-col v-for="l in locs" :key="l">
                            <img
                                class="d-block mx-auto"
                                height="250"
                                :src="`${$store.state.uploads}/news/${id}/${value.text[l]}`"
                            />
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 5">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-file-input
                                :label="`Загрузка [${l}]`"
                                :rules="value.id < 0 ? req : []"
                                accept="video/mp4"
                                v-model="value.file[l]"
                            />
                        </v-col>
                    </v-row>
                    <v-row v-if="value.id > 0">
                        <v-col v-for="l in locs" :key="l">
                            <video
                                class="d-block mx-auto"
                                :src="`${$store.state.uploads}/news/${id}/${value.text[l]}`"
                                height="250"
                                controls
                            />
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 6">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-btn
                                block
                                @click="addImageFiles(l)"
                                color="primary"
                                :disabled="loading"
                            >
                                Добавить файлы [{{ l }}]
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-alert
                                type="error"
                                :value="value.data[l].length == 0"
                            >
                                Добавьте файлы
                            </v-alert>
                            <v-row>
                                <v-col
                                    cols="auto"
                                    v-for="(d, i) in value.data[l]"
                                    :key="d.id"
                                >
                                    <v-card>
                                        <v-toolbar flat dense>
                                            <v-spacer />
                                            <v-btn
                                                icon
                                                @click="
                                                    sortImage(false, d.id, l)
                                                "
                                                :disabled="i == 0 || loading"
                                            >
                                                <v-icon>mdi-arrow-left</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                @click="
                                                    sortImage(true, d.id, l)
                                                "
                                                :disabled="
                                                    i ==
                                                        value.data[l].length -
                                                            1 || loading
                                                "
                                            >
                                                <v-icon>mdi-arrow-right</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                color="error"
                                                @click="removeImage(d.id, l)"
                                                :disabled="loading"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text>
                                            <img
                                                :src="d.preview"
                                                width="60"
                                                height="60"
                                                class="d-block mx-auto"
                                                style="object-fit: cover"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 7">
                    <v-divider />
                </template>
                <template v-if="value.type == 8">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-btn
                                block
                                @click="addImageFiles(l)"
                                color="primary"
                                :disabled="loading"
                            >
                                Добавить файлы [{{ l }}]
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-alert
                                type="error"
                                :value="value.data[l].length == 0"
                            >
                                Добавьте файлы
                            </v-alert>
                            <v-row v-for="(d, i) in value.data[l]" :key="d.id">
                                <v-col>
                                    <v-card>
                                        <v-toolbar flat dense>
                                            <v-spacer />
                                            <v-btn
                                                icon
                                                @click="
                                                    sortImage(false, d.id, l)
                                                "
                                                :disabled="i == 0 || loading"
                                            >
                                                <v-icon>mdi-arrow-up</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                @click="
                                                    sortImage(true, d.id, l)
                                                "
                                                :disabled="
                                                    i ==
                                                        value.data[l].length -
                                                            1 || loading
                                                "
                                            >
                                                <v-icon>mdi-arrow-down</v-icon>
                                            </v-btn>
                                            <v-btn
                                                icon
                                                color="error"
                                                @click="removeImage(d.id, l)"
                                                :disabled="loading"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-toolbar>
                                        <v-card-text>
                                            <v-row align="center">
                                                <v-col cols="auto">
                                                    <img
                                                        :src="d.preview"
                                                        width="60"
                                                        height="60"
                                                        class="rounded-circle"
                                                        style="
                                                            object-fit: cover;
                                                        "
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        label="Текст"
                                                        :rules="req"
                                                        v-model="d.text"
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 9">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <prism-editor
                                class="my-editor"
                                v-model="value.text[l]"
                                :highlight="highlighter"
                                line-numbers
                            />
                        </v-col>
                    </v-row>
                </template>
                <template v-if="value.type == 10">
                    <v-row>
                        <v-col v-for="l in locs" :key="l">
                            <v-text-field
                                :label="`Путь до компонента [${l}]`"
                                :rules="req"
                                v-model="value.text[l]"
                            />
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions v-if="dialog">
                <v-spacer />
                <v-btn
                    text
                    color="error"
                    :disabled="loading"
                    @click="remove(value.id)"
                >
                    Отмена
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    :disabled="disableSave"
                    type="submit"
                >
                    Сохранить
                </v-btn>
            </v-card-actions>
        </v-card>
        <file-uploader ref="uploader" />
    </v-form>
</template> 

<style lang="scss" scoped>
/* required class */
.my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}
</style>

<script>
import Editor from "@tinymce/tinymce-vue";
import FileUploader from "@c/FileUploader";
// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
export default {
    name: "NewsOneItem",
    components: {
        Editor,
        FileUploader,
        PrismEditor,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        types: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        dialog: {
            type: Boolean,
        },
        first: {
            type: Boolean,
        },
        last: {
            type: Boolean,
        },
    },
    data: () => ({
        loading: false,
        localId: 0,
    }),
    computed: {
        locs: () => ["ru", "en"],
        req: () => [(v) => !!v || "Заполните поле"],
        reqLvl: () => [
            (v) => !!v || "Заполните поле",
            (v) => (v > 0 && v <= 4) || "1-4",
        ],
        mce: () => ({
            height: 300,
            menubar: false,
            language: "ru",
        }),
        disableSave() {
            if (this.loading) {
                return true;
            }
            if (this.value.type == 8 || this.value.type == 6) {
                for (let d of Object.values(this.value.data)) {
                    if (!d.length) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    methods: {
        highlighter(code) {
            return highlight(code, languages.js); //returns html
        },
        addImageFiles(loc) {
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = true;
            input.accept = "image/jpeg, image/png";
            input.addEventListener("change", () => {
                input.files.forEach((f) => {
                    const r = new FileReader();
                    r.onload = () => {
                        const one = {
                            id: --this.localId,
                            file: f,
                            preview: r.result,
                        };
                        if (this.value.type == 8) {
                            one.text = "";
                        }
                        this.value.data[loc].push(one);
                    };
                    r.readAsDataURL(f);
                });
            });
            input.click();
        },
        async removeImage(id, loc) {
            if (!(await this.$confirm())) {
                return;
            }
            this.value.data[loc] = this.value.data[loc].filter(
                (d) => d.id != id
            );
        },
        sortImage(dir, id, loc) {
            const list = [...this.value.data[loc]];
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    let target = list[i];
                    let another = list[dir ? i + 1 : i - 1];
                    [list[i], list[dir ? i + 1 : i - 1]] = [another, target];
                    break;
                }
            }
            this.value.data[loc] = list;
        },
        async saveContent() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.loading = true;
            const body = { ...this.value };
            switch (body.type) {
                case 4:
                case 5: {
                    this.value.file = { ru: null, en: null };
                    const needUpload = [];
                    if (body.file.ru) {
                        body.file.ru.id = "ru";
                        needUpload.push(body.file.ru);
                    }
                    if (body.file.en) {
                        body.file.en.id = "en";
                        needUpload.push(body.file.en);
                    }
                    const files = await this.$refs.uploader.upload(needUpload);
                    body.file = {};
                    files.forEach((f) => (body.file[f.id] = f.tmp));
                    break;
                }
                case 6:
                case 8: {
                    const needUpload = [];
                    this.locs.forEach((l) => {
                        body.data[l].forEach((d) => {
                            if (d.file) {
                                d.file.id = { l, id: d.id };
                                needUpload.push(d.file);
                            }
                        });
                    });
                    const files = await this.$refs.uploader.upload(needUpload);
                    files.forEach((f) => {
                        for (let d of body.data[f.id.l]) {
                            if (d.id == f.id.id) {
                                d.file = f.tmp;
                                break;
                            }
                        }
                    });
                }
            }

            const { id, data, text } = await this.$admin(
                `/news/${this.id}/content`,
                {
                    method: "POST",
                    body,
                }
            );
            if (this.value.id < 0) {
                this.value.id = id;
            }
            if (this.value.type == 8 || this.value.type == 6) {
                Object.values(data).forEach((loc) => {
                    loc.forEach((d) => {
                        d.preview = `${this.$store.state.uploads}/news/${this.id}/${d.src}`;
                    });
                });
            }
            this.value.data = data;
            this.value.text = text;
            this.$emit("saved", this.value);
            this.loading = false;
        },
        getTypeName(id) {
            for (let t of this.types) {
                if (t.id == id) {
                    return t.name;
                }
            }
            return "";
        },
        async remove(id) {
            const c = await this.$confirm();
            if (!c) {
                return false;
            }
            if (id > 0) {
                this.loading = true;
                await this.$admin(`/news/${this.id}/content/${id}`, {
                    method: "DELETE",
                });
                this.loading = false;
            }
            this.$emit("removed", id);
        },
    },
};
</script>